// ==============================
// * PAGE DESIGNER: ENHANCED BANNER
//
// ? This file gathers all the custom Highlight Content CSS.
// ==============================

@import "../../variables";
@import "../../../abstract/functions";
@import "../../../abstract/mixins";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.enhanced-banner {
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        &.zoom-effect:hover {
            .enhanced-banner__image img {
                transform: scale(1.2);
            }
        }
    }

    &__container {
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }

        &__item {
            position: relative;
        }
    }

    &__content {
        width: 100%;

        @include media-breakpoint-up(md) {
            @include element-centered-X-Y;

            text-align: center;

            &.left {
                left: 0;
                transform: translate(0, -50%);
                text-align: left;
            }

            &.right {
                left: initial;
                right: 0;
                transform: translate(0, -50%);
                text-align: right;
            }
        }

        img {
            max-width: 70px;
            margin-bottom: 10px;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }


        &__text {
            * {
                color: $black !important; // !important to override inline SFRA style in WYSIWYG
            }

            &.dark {
                * {
                    @include media-breakpoint-up(md) {
                        color: $white !important; // !important to override inline SFRA style in WYSIWYG
                    }
                }
            }

            h1,
            h2,
            h3,
            h4 {
                margin-bottom: 20px;
                font-size: rem(30px);
                text-transform: uppercase;

                @include media-breakpoint-up(md) {
                    font-size: rem(40px);
                }
            }
        }

        &__btns {
            margin: 10px -10px 0;

            a {
                width: calc(50% - 22px);
                margin: 10px;

                @include media-breakpoint-up(md) {
                    width: auto;

                    &.btn-secondary {
                        box-shadow: none;
                        color: $black !important; // Override boostrap var

                        &:hover,
                        &:focus,
                        &:active,
                        &.active {
                            border-color: $primary;
                            color: $secondary !important; // Override boostrap var
                        }
                    }
                }
            }
        }
    }

    &__image {
        img {
            @include make-transition;

            width: 100%;
        }
    }
}
